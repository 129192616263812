import React from 'react'

export default function ProjectMeta(props) {
  return (
    <div className='projectMeta-container'>
      <div className='projectMeta-userpic' style={{backgroundImage: `url(${props.userpic})`}}></div>

      <div className='projectMeta-info'>
        <span className='general'>{props.authorName}</span>
        <span className='general italic'>{props.views}&nbsp;просмотров</span>
      </div>
    </div>
  )
}
