import React from 'react'
import YouTube from 'react-youtube'

export default class ModalPlayer extends React.Component {
  constructor() {
    super()
  }

  _onReady(e) {
    e.target.playVideo()
  }

  render() {
    const opts = {
      playerVars: {
        autoplay: 1,
        controls: 1,
        start: this.props.start,
        loop: 1
      },
    }

    return (
      <div className='mvp-container' onClick={this.props.onClick}>
        <div className='player'>
          <YouTube
            id                 = {this.props.videoID}
            videoId            = {this.props.videoID}
            containerClassName = {'embededVideo-container'}
            onReady            = {this._onReady}
            opts               = {opts}
          />
        </div>

        <div className='mvpCloseButton mobile'>Закрыть</div>
      </div>
    )
  }
}
