import React from 'react'

import PlaybackButton from '../../assets/icons/PlaybackButton.svg'

export default function VideoThumbnail(props) {
  return (
    <div
      className = 'projectItem-video'
      onClick   = {props.onClick}
    >
      <img
        src       = {PlaybackButton}
        alt       = 'play'
        className = 'playbackButton'
      />

      <div
        className = 'embededVideoThumbnail'
        style     = {{backgroundImage: `url(https://img.youtube.com/vi/${props.videoID}/maxresdefault.jpg)`}}
      >
        <div className='overlay'></div>
      </div>
    </div>
  )
}
